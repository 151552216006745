import type {
  InputSearchQuery,
  StateSearchLocationFilters,
} from '~/types/search/types'

export const useSearchLocationFilters = () => {
  const { $api } = useNuxtApp()
  const stateSearchLocationFilters = useState<StateSearchLocationFilters>(
    'state-search-location-filter',
  )

  const queryValue = computed(
    () => stateSearchLocationFilters.value.query as InputSearchQuery,
  )
  const queryValueMultiple = computed(
    () => stateSearchLocationFilters.value.queryMultiple,
  )
  const queryHouseIds = computed(
    () => stateSearchLocationFilters.value.queryMultipleHousesIds,
  )
  const queryDestinationIds = computed(
    () => stateSearchLocationFilters.value.queryMultipleDestinationsIds,
  )

  const apiParamsDestinationIds = computed(() => {
    const ids: string[] = []
    if (queryDestinationIds.value) ids.push(...queryDestinationIds.value)
    if (queryValue.value.value) ids.push(String(queryValue.value.value))

    return ids
  })

  const setLocation = (destinationName: string, destinationId: number) => {
    stateSearchLocationFilters.value.query.label = destinationName
    stateSearchLocationFilters.value.query.value = destinationId
  }

  const setMultipleLocations = (locations: InputSearchQuery[]) => {
    stateSearchLocationFilters.value.queryMultiple = locations
    stateSearchLocationFilters.value.queryMultipleHousesIds = locations
      .filter((l) => l.type === 'type-of-house')
      .map((l) => String(l.value))
    stateSearchLocationFilters.value.queryMultipleDestinationsIds = locations
      .filter(
        (l) => l.type === 'location' && l.value !== queryValue.value.value,
      )
      .map((l) => String(l.value))

    const locationsIds = locations
      .filter((l) => l.type === 'location' && l.value)
      .map((l) => l.value)

    if (
      import.meta.client &&
      queryValue.value.value &&
      !locationsIds.includes(queryValue.value.value)
    ) {
      clearLocationFilters()
    }
  }

  const clearLocationFilters = () => {
    stateSearchLocationFilters.value.query.label = undefined
    stateSearchLocationFilters.value.query.value = undefined
  }

  const clearMultipleLocationsFilters = () => {
    stateSearchLocationFilters.value.query.label = undefined
    stateSearchLocationFilters.value.query.value = undefined

    setMultipleLocations([])
  }

  const fetchParentLocation = async (locationId: number) => {
    let parentDestiId: null | number = null

    try {
      const res = await $api.v3.destination.getById({
        id: locationId,
      })
      const { cluster, country, name } = res.data

      if (locationId !== Number(cluster) || locationId !== Number(country)) {
        if (locationId !== Number(cluster)) {
          parentDestiId = Number(cluster)
        } else if (locationId !== Number(country)) {
          parentDestiId = Number(cluster)
        }
      }

      if (parentDestiId) setLocation(name, parentDestiId)
    } catch (err) {
      console.error(err)
    }
  }

  return {
    apiParamsDestinationIds,
    clearLocationFilters,
    clearMultipleLocationsFilters,
    fetchParentLocation,
    queryDestinationIds,
    queryHouseIds,
    queryValue,
    queryValueMultiple,
    setLocation,
    setMultipleLocations,
  }
}
