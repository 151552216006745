<script setup lang="ts">
import type { SearchFacetValue, SearchFacetObject } from '~/types/search/types'

const props = withDefaults(
  defineProps<{
    disabled: boolean
    displayRow: boolean
    options: {
      description?: string
      isActive: boolean
      label: string
      value: [SearchFacetObject<SearchFacetValue[]>, SearchFacetValue]
    }[]
  }>(),
  {
    disabled: false,
    displayRow: false,
    options: () => [],
  },
)
const emits = defineEmits<{
  select: [SearchFacetObject<SearchFacetValue[]>, SearchFacetValue]
}>()

const selectOption = (
  value: [SearchFacetObject<SearchFacetValue[]>, SearchFacetValue],
) => {
  if (!props.disabled) emits('select', ...value)
}
</script>

<template>
  <div :class="{ 'grid lg:grid-cols-2 gap-y-4': !displayRow }">
    <div v-for="(option, i) in options" :key="`${option.label}-${i}`">
      <BaseFormComponentsBaseCheckbox
        color="secondary"
        id-form="facets"
        text-checkbox-class="text-base font-[400]"
        wrapper-class="w-full m-0 text-base"
        :disabled="disabled"
        :label="option.label"
        :model-value="!!option.isActive"
        :name="option.label"
        @change="selectOption(option.value)"
      />
      <button
        v-if="option.description"
        :class="[
          'text-base text-gray-500 pl-6 text-left',
          { 'cursor-wait': disabled },
        ]"
        :disabled="disabled"
        type="button"
        @click="selectOption(option.value)"
      >
        {{ option.description }}
      </button>
    </div>
  </div>
</template>
