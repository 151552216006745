import { createIndexesFilterFacets } from '~/helpers/search/createIndexesFilterFacets'

export const useSearch = async ({
  loadDesti = true,
  loadFacetSeo = false,
  locale,
}) => {
  const { $api, $strapiApi } = useNuxtApp()
  const config = useRuntimeConfig()
  const route = useRoute()
  const stateDestination = useState('destination')

  if (loadDesti) {
    const {
      collections,
      destination,
      destinations,
      linksSeo,
      lpContent,
      metaCoverPhotoUrl,
      metaDesc,
      metaTitle,
    } = await useSearchDesti({ $api, config, route, $strapiApi })

    const destinationId = ref(destination.value?.id)
    const seoTitle = ref(destination.value?.searchTextTitle || '')
    stateDestination.value = {
      collections: collections.value,
      destination: destination.value,
      destinationId: destinationId.value,
      destinations,
      disableQuery: false,
      indexationPage: true,
      isDestinationPage: false,
      isIndexesFilter: false,
      linksSeo,
      lpContent: lpContent.value,
      metaCoverPhotoUrl,
      metaDesc,
      metaTitle,
      seoTitle: seoTitle.value,
      queryOutput: route.query,
    }
  } else if (loadFacetSeo) {
    const { destination, metaDesc, metaTitle, seoTitle, indexation } =
      await useSearchFilterUrl({ $api, $strapiApi, locale, route })

    const formatFacets = await createIndexesFilterFacets({
      routeParams: route.params,
      $strapiApi,
      locale: locale.value,
    })

    stateDestination.value = {
      ...stateDestination.value,
      destination: destination.value,
      destinationId: destination.value?.id || null,
      disableQuery: true,
      indexationPage: indexation,
      isDestinationPage: false,
      isIndexesFilter: true,
      linksSeo: [],
      metaDesc,
      metaTitle,
      seoTitle,
      queryOutput: formatFacets,
    }
  }
}
