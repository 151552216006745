<script setup lang="ts">
import type { Locale } from 'lc-services/types'
import type {
  SearchFacetObject,
  SearchFacetTags,
  SearchFacetValue,
} from '~/types/search/types'

const { locale } = useI18n<unknown, Locale>()
const { userIsAdmin } = useAuth()

const { isExclusivityFilter, setExclusivityFilter } =
  useSearchFiltersExclusivity()

const props = withDefaults(
  defineProps<{
    collapse?: boolean
    collapseOpenDefault?: boolean
    displayRow?: boolean
    facets: Record<SearchFacetTags, Record<string, number>>
    facetFilters: Record<string, string[]>
    loadingFacets?: boolean
    title?: string
    type?: 'labels' | 'checkboxes'
    topFacets?: SearchFacetValue<Record<Locale, string>, false>[]
  }>(),
  {
    collapse: false,
    collapseOpenDefault: false,
    displayRow: false,
    loadingFacets: false,
    title: '',
    type: 'labels',
    topFacets: () => [],
  },
)

const emits = defineEmits<{
  'change-exclu': []
  'change-facet': [
    SearchFacetObject,
    SearchFacetValue<Record<Locale, string>, false>,
  ]
}>()

const getTopFacetTag = (value: { key: string | number }) => {
  const tag: SearchFacetTags =
    value.key === 'other_tags'
      ? `other_tags.${locale.value}`
      : `searchable_tags.${Number(value.key)}.${locale.value}`

  return tag
}

const topFacet = computed(() => {
  const values = (
    JSON.parse(JSON.stringify(props.topFacets)) as typeof props.topFacets
  )
    .filter((topFacet) => {
      const tag = getTopFacetTag(topFacet)

      if (tag && props.facets[tag]) {
        return Object.keys(props.facets[tag]).includes(
          topFacet.value[locale.value],
        )
      }

      return false
    })
    .map((topFacet) => {
      topFacet.checked =
        props.facetFilters[topFacet.key]?.some((f) =>
          f.includes(topFacet.value[locale.value]),
        ) || false

      return topFacet
    })
    .map((f) => {
      return {
        ...f,
        value: f.value[locale.value as Locale],
      }
    })

  return { values } as SearchFacetObject<SearchFacetValue<string, false>[]>
})

const changeFacet = (
  facet: SearchFacetObject,
  facetValue: SearchFacetValue<Record<Locale, string>, false>,
) => {
  const facetFormatted = {
    ...facet,
    key:
      facetValue.key === 'other_tags' ? facetValue.key : Number(facetValue.key),
    tag: getTopFacetTag(facetValue),
  } as SearchFacetObject

  emits('change-facet', facetFormatted, facetValue)
}

// Exclu
const showExclu = () => {
  setExclusivityFilter(!isExclusivityFilter.value)

  emits('change-exclu')
}
</script>

<template>
  <div>
    <SearchFilterFacet
      :collapse="collapse"
      :collapse-open-default="collapseOpenDefault"
      :display-row="displayRow"
      :facet="topFacet"
      :loading-facets="loadingFacets"
      :sort-alphabetic-results="false"
      :title="title"
      :type="type"
      @changeFacet="changeFacet"
    >
      <template v-if="userIsAdmin" #filter-button-checkbox>
        <BaseFormComponentsBaseCheckbox
          :model-value="isExclusivityFilter"
          :disabled="loadingFacets"
          :label="`${$t('search.exclusivityFilter')} ⭐️`"
          name="exclusivityFilter"
          color="secondary"
          id-form="facets"
          text-checkbox-class="text-base font-[400]"
          wrapper-class="w-full m-0 text-base"
          @change="showExclu"
        />
      </template>
    </SearchFilterFacet>
  </div>
</template>
