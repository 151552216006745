<script setup lang="ts">
const props = withDefaults(
  defineProps<{
    name?: string
    right?: boolean
    modelValue: boolean
  }>(),
  {
    name: '',
    right: false,
  },
)
const emits = defineEmits<{
  'update:model-value': [value: boolean]
}>()
defineSlots<{ button(): any; content(): any }>()

const toggleDropdown = () => {
  emits('update:model-value', !props.modelValue)
}
const closeDropdown = () => {
  emits('update:model-value', false)
}
</script>

<template>
  <div
    :ref="`dropdown-${name}`"
    v-click-outside="closeDropdown"
    :class="{ 'is-active': modelValue }"
  >
    <div class="cursor-pointer" @click.prevent="toggleDropdown">
      <slot name="button" aria-haspopup="true" aria-controls="openDropdown" />
    </div>
    <div
      v-if="modelValue"
      id="openDropdown"
      class="absolute w-full mt-2 bg-white rounded-sm shadow-sm dropdown-content md:w-auto z-dropdown"
      :class="{ 'right-0': right }"
      role="menu"
    >
      <slot name="content" />
    </div>
  </div>
</template>

<style scoped>
.dropdown-content {
  box-shadow: 0 15px 30px 10px rgb(0 0 0 / 8%);
}
</style>
