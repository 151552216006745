import type { Locale } from 'lc-services/types'

export const buildFiltersAppliedSearchTracking = ({
  bathrooms = 0,
  bedrooms = 0,
  capacity = 0,
  destinationId,
  endDate,
  facetsFiltersParams,
  locale,
  maxBudget,
  minBudget,
  searchableTagsIdentifiers = [],
  startDate,
}: {
  bathrooms: number
  bedrooms: number
  capacity: number
  destinationId: string
  endDate: string | null
  facetsFiltersParams: string[]
  locale: Locale
  maxBudget: string
  minBudget: string
  searchableTagsIdentifiers: Record<string, { en: string; fr: string }>[]
  startDate: string | null
}) => {
  const tracking: {
    capacity?: number
    bedrooms?: number
    bathrooms?: number
    min_budget?: string
    max_budget?: string
    desti_id?: string
    dates_selected?: boolean
    facets?: string[]
  } = {}

  if (capacity) tracking.capacity = capacity
  if (bedrooms) tracking.bedrooms = bedrooms
  if (bathrooms) tracking.bathrooms = bathrooms
  if (minBudget !== '0') tracking.min_budget = minBudget
  if (maxBudget !== '1000000') tracking.max_budget = maxBudget
  if (destinationId) tracking.desti_id = destinationId

  tracking.dates_selected = !!(startDate && endDate)

  if (facetsFiltersParams?.length) {
    tracking.facets = facetsFiltersParams?.reduce<string[]>((acc, current) => {
      const facetName = current.split(':')?.[1]?.trim()
      const searchableTag = searchableTagsIdentifiers?.find((searchableTag) => {
        const values = Object.values(searchableTag)?.[0]

        return values?.[locale] === facetName
      })

      if (searchableTag) {
        const identifier = Object.keys(searchableTag)?.[0]
        if (identifier) acc.push(identifier)
      }

      return acc
    }, [])
  }

  return tracking
}
