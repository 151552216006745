<template>
  <div class="border border-gray-200">
    <div class="w-full h-[227px] lg:h-[275px] animate-wave" />
    <div class="p-4">
      <div class="w-1/3 h-6 mb-1 animate-wave" />
      <div class="w-2/3 h-5 mb-3 animate-wave" />
      <div class="flex items-center">
        <div class="text-gray-600 w-2/12 h-5 animate-wave" />
        <div class="text-gray-400 px-2">●</div>
        <div class="text-gray-600 w-2/12 h-5 animate-wave" />
        <div class="text-gray-400 px-2">●</div>
        <div class="text-gray-600 w-2/12 h-5 animate-wave" />
      </div>
      <div class="w-full border-t border-gray-200 mt-3" />
      <div class="w-4/5 h-5 border-t border-gray-200 mt-3 animate-wave" />
    </div>
  </div>
</template>
