export const useSearchFiltersExclusivity = () => {
  const isExclusivityFilter = useState('filter-exclusivity', () => false)
  const setExclusivityFilter = (value: boolean) =>
    (isExclusivityFilter.value = value)

  return {
    isExclusivityFilter,
    setExclusivityFilter,
  }
}
