export const createDates = ({ query, $httpCookies }) => {
  const cookies = $httpCookies?.get('search_params')
  const hasDateCookie = ref(!!(cookies?.from_date && cookies?.to_date))

  const startDate = ref(null)
  const endDate = ref(null)

  if (query.startDate && query.endDate) {
    startDate.value = query.startDate
    endDate.value = query.endDate

    $httpCookies.set('search_params', {
      from_date: startDate.value,
      to_date: endDate.value,
    })
  } else if (cookies?.from_date && cookies?.to_date) {
    startDate.value = cookies.from_date
    endDate.value = cookies.to_date
  }

  return {
    endDate,
    hasDateCookie,
    startDate,
  }
}
