import {
  getFacetsFilterWithDestination,
  getFacetsFilterWithoutDestination,
} from '~/helpers/strapi/getFacetsFilter'

export const createIndexesFilterFacets = async ({
  routeParams,
  $strapiApi,
  locale,
}) => {
  let facetsUrlIndexes = null
  if (routeParams.name) {
    facetsUrlIndexes = await getFacetsFilterWithoutDestination({
      $strapiApi,
      slug: routeParams.name,
    })
  } else {
    facetsUrlIndexes = await getFacetsFilterWithDestination({
      $strapiApi,
      destination_slug: routeParams.slug,
      slug: routeParams.filter,
    })
  }

  let facetFilter = []
  if (facetsUrlIndexes?.facets?.[locale]) {
    facetFilter = Object.values(facetsUrlIndexes.facets[locale]).map(
      (value) => value[0],
    )
  }

  const formatFacets = {
    facetFilter,
  }

  return formatFacets
}
