<script setup lang="ts">
defineEmits<{ 'clear-refinements': [] }>()
defineSlots<{ default(): any }>()

const localePath = useLocalePath()
const buttonClicked = ref(false)
</script>

<template>
  <div>
    <div class="lc-container search-no-results">
      <h5
        class="mt-8 mb-4 text-center text-2xl text-secondary-600 max-w-[310px] tablet:max-w-full mx-auto"
      >
        {{ $t('search.noResultsTitle') }}
      </h5>

      <i18n-t
        class="text-center m-0 max-w-[310px] tablet:max-w-full mx-auto"
        keypath="search.noResultsText"
        tag="p"
      >
        <template #link>
          <BaseButton
            variant="link"
            class="search-no-results__link"
            @click="$emit('clear-refinements')"
          >
            {{ $t('search.noResultsLink') }}
          </BaseButton>
        </template>
      </i18n-t>

      <div
        class="mt-8 text-sm flex flex-col tablet:flex-row items-center lg:w-1/2 lg:h-14 mx-auto"
      >
        <BaseSlidingInputContainer
          :button-text="
            buttonClicked ? $t('global.validate') : $t('global.ctaCallMe')
          "
          :no-icon="buttonClicked"
          :notes="localePath('search')"
          class="flex-1"
          section="no results"
          @showInput="(value: boolean) => (buttonClicked = value)"
        />
        <BaseButton
          :to="localePath('contact')"
          color="black"
          class="mt-8 tablet:mt-0 flex-1 justify-center"
          has-icon
          variant="link"
        >
          <BaseIcon class="mr-3 md:mr-4" name="mail" />
          <span class="pl-2">{{ $t('global.contactUs') }}</span>
        </BaseButton>
      </div>
    </div>

    <slot />
  </div>
</template>

<style>
.search-no-results__link {
  @apply block mx-auto;
}

@screen tablet {
  .search-no-results__link {
    @apply inline-block;
  }
}

.search-no-result__title-wrap {
  @apply flex justify-between	items-baseline;
}

@screen lg {
  .search-no-results__btn-wrap {
    @apply flex justify-end	self-end;
    height: min-content;
  }
}

.search-no-results__carousel .carrousel-arrow__wrap--next {
  right: 0.9rem;
  top: calc(50% - 50px);
}

.search-no-results__carousel .carrousel-arrow__wrap--prev {
  left: 0.9rem;
  top: calc(50% - 50px);
}

.search-no-results .sliding-input {
  @apply mt-0 items-center justify-center;
}
</style>
