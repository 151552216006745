<script setup lang="ts">
const localePath = useLocalePath()
const route = useRoute()

const props = defineProps<{
  page: number
}>()
defineEmits<{
  'previous-page': []
}>()

const isServerSide = computed(() => import.meta.server)

const serverUrl = () => {
  const query: { page?: number } = {
    ...route.query,
    page: props.page - 1,
  }

  if (props.page === 1) {
    delete query.page
  }

  if (route.name?.toString()?.includes('destination')) {
    return localePath({
      name: 'destination-name',
      params: {
        name: route.params.name,
        destination: route.params.destination,
      },
      query,
    })
  }

  return localePath({
    name: 'search',
    query,
  })
}
</script>

<template>
  <BaseButton
    v-show="isServerSide"
    class="m-3"
    color="secondary"
    :to="serverUrl()"
    variant="link"
  >
    <BaseIcon name="arrowLeft" />
    <span>{{ $t('global.back') }}</span>
  </BaseButton>
  <BaseButton
    v-show="!isServerSide"
    class="m-3"
    color="secondary"
    has-icon
    variant="link"
    @click="$emit('previous-page')"
  >
    <BaseIcon name="arrowLeft" />
    {{ $t('global.back') }}
  </BaseButton>
</template>
