import type { LocationQuery } from 'vue-router'

export const createNumericFilters = (query: LocationQuery) => {
  const selectedNumericFilters = {
    bathrooms: 0,
    bedrooms: 0,
    capacity: 0,
  }

  if (query?.numericFilters) {
    const numericFilters = query.numericFilters
      .toString()
      .split('+')
      .join(' ')
      .split(' AND ')

    numericFilters.forEach((numericFilter) => {
      const keyValue = numericFilter.split(' >= ') as [
        'bathrooms' | 'bedrooms' | 'capacity',
        string,
      ]
      const key = keyValue[0]
      const value = keyValue[1]

      selectedNumericFilters[key] = Number(value)
    })
  }

  return { selectedNumericFilters }
}
