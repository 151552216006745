import type {
  StateSearchReplicaFilters,
  ReplicasValues,
} from '~/types/search/types'

export const useSearchReplicaFilters = () => {
  const stateSearchReplicaFilters = useState<StateSearchReplicaFilters>(
    'state-search-replica-filter',
  )

  const currentReplica = computed(
    () => stateSearchReplicaFilters.value.currentReplica,
  )
  const replicas = computed(() => stateSearchReplicaFilters.value.replicas)

  const setReplicaFilters = (newReplica: {
    label: ReplicasValues
    value: string
  }) => {
    stateSearchReplicaFilters.value.currentReplica = newReplica
  }

  return {
    currentReplica,
    replicas,
    setReplicaFilters,
  }
}
