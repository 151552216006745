export const useSearchFiltersCount = () => {
  const filtersCount = useState('filter-count')

  const setFiltersCount = (value) => (filtersCount.value = value)

  return {
    filtersCount,
    setFiltersCount,
  }
}
