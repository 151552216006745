import type { LocationQuery } from 'vue-router'

export const handlePage = (query?: LocationQuery, isMobile = false) => {
  const page = query?.page ? ref(Number(query.page)) : ref(0)

  return {
    housesPerPage: ref(isMobile ? 20 : 30),
    nextPage: ref(page.value + 1),
    page,
    previousPage: ref(page.value > 1 ? page.value - 1 : 0),
    showPreviousButton: ref(page.value > 0),
  }
}
