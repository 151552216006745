<script setup lang="ts">
import { $dayjs } from '@/utils/dayjs'

import { useButtons } from '~/components/BaseForm/compose/use-buttons'
import formWaitingListDesktop from '~/forms/search/waitingList/desktop/form/groupFields'
import formWaitingListMobile from '~/forms/search/waitingList/mobile/form/groupFields'
import type { DateString } from '~/helpers/utils/regex'
import type { SendMutationType } from '~/helpers/BaseForm/sendMutation'
import type { MutationType } from '../BaseForm/types'

const props = withDefaults(
  defineProps<{
    banner?: boolean
    bathrooms?: number | null
    bedrooms?: number | null
    capacity?: number | null
    currency?: string
    destinationId: number
    endDate?: DateString | null
    guests?: number | null
    maxBudget?: string
    minBudget?: string
    startDate?: DateString | null
    totalResults?: number
  }>(),
  {
    banner: false,
    bathrooms: null,
    bedrooms: null,
    capacity: null,
    currency: '',
    endDate: $dayjs().format('YYYY-MM-DD') as DateString,
    guests: null,
    maxBudget: '',
    minBudget: '',
    startDate: $dayjs().format('YYYY-MM-DD') as DateString,
    totalResults: 0,
  },
)

const { $api } = useNuxtApp()
const { isMobile } = useDevice()
const { t } = useI18n()
const { user } = useUser()
const { trackEvent } = useTracking()
const { isSmallScreen } = useBreakpoint()

const { btnPrimary } = useButtons()
btnPrimary.text = computed(() => t('footer.send'))
if (btnPrimary.attrs) {
  btnPrimary.attrs.blockFull = true
  btnPrimary.attrs.color = 'primary'
}

const waitingListCard = ref(null)
const alreadySubscribed = ref(false)
const apiErrors = ref({})
const coverPhotoUrl = ref('')
const destination = ref<{ metaTitle?: string; name?: string }>({})
const newsletterSuccess = ref(false)
const sendAnalytics = ref(false)
const waitingListSuccess = ref(false)

const bannerImage = computed(() =>
  isSmallScreen.value ? '' : coverPhotoUrl.value,
)

const initialData = reactive({
  email: user.value.email,
})

const groupFieldsFormWaitingListMobile = reactive(formWaitingListMobile())
const groupFieldsFormWaitingListDesktop = reactive(formWaitingListDesktop())

const submitNewsletter = () => {
  if (!newsletterSuccess.value) {
    try {
      $api.v1.newsletterEmails.create({ email: initialData.email })

      newsletterSuccess.value = true
      trackEvent({
        event: 'newsletter_subscription',
        label: 'search',
        post_alert: true,
      })
    } catch (err: any) {
      if (err.response?.data && err.response?.status === 400) {
        if (
          err?.response?.data.errors?.[0].newsletter.email?.[0].error ===
          'taken'
        ) {
          newsletterSuccess.value = true
        } else {
          apiErrors.value = err.response.data
        }
      }
    }
  }
}

const constructParams = (formData: { email: string }) => {
  const startDate = $dayjs(props.startDate).format('YYYY-MM-DD')
  const endDate = $dayjs(props.endDate).format('YYYY-MM-DD')
  const equal = startDate === endDate

  const params = {
    bedrooms: props.bedrooms,
    bathrooms: props.bathrooms,
    min_budget: props.minBudget,
    max_budget: props.maxBudget,
    check_in_date: equal ? null : startDate,
    check_out_date: equal ? null : endDate,
    currency: props.minBudget || props.maxBudget ? props.currency : '',
    people: props.capacity,
  }

  const formatParams = Object.keys(params)
    .filter((key) => params[key as keyof typeof params])
    .reduce(
      (acc, curr) => ({ ...acc, [curr]: params[curr as keyof typeof params] }),
      {},
    )

  return {
    ...formatParams,
    destination_id: props.destinationId,
    email: formData.email,
  }
}

const submitWaitingList = (data: ReturnType<typeof constructParams>) =>
  $api.v2.waitingSearches.create(data)

const mutationWaitingList: MutationType<{ email: string }> = (data) => ({
  request: () => submitWaitingList({ ...constructParams(data) }),
  input: {},
})

const onSubmitWaitingList: () => SendMutationType['onSubmit'] = () => ({
  success: () => {
    waitingListSuccess.value = true
    trackEvent({
      event: 'destination_alert_subscription',
      destination_alert: destination.value.name,
    })
  },
  error: (err: any) => {
    if (err.response?.data && err.response?.status === 400) {
      if (
        err.response.data?.errors?.[0]?.waiting_search?.destination_id?.[0]
          ?.error === 'taken'
      ) {
        alreadySubscribed.value = true
      } else {
        apiErrors.value = err.response.data
      }
    }
  },
})

const fetchDestination = async () => {
  try {
    const desti = await $api.v3.destination.getPhoto({
      id: props.destinationId,
    })

    coverPhotoUrl.value = desti.coverPhotoUrl || desti.seoPhotoUrl
    destination.value = desti
  } catch (err) {
    console.error(err)

    throw err
  }
}

await fetchDestination()

watch(
  () => props.destinationId,
  async () => {
    waitingListSuccess.value = false
    sendAnalytics.value = false

    await fetchDestination()
  },
)
</script>

<template>
  <div
    ref="waitingListCard"
    :class="['bg-gray-100 items-center', { border: banner }]"
  >
    <BaseMediaObject>
      <template v-if="banner" #image>
        <BaseNuxtImg
          :alt="destination.metaTitle || ''"
          aspect-ratio="16/9"
          :src="bannerImage"
        />
      </template>

      <template #content>
        <div class="h-full flex flex-col justify-center">
          <div
            :class="{
              'max-w-md p-8 m-auto lg:max-w-xl': banner,
              'flex flex-col justify-center h-full px-5 py-10 rounded-sm md:px-10':
                !banner,
            }"
          >
            <div class="text-center md:text-left text-5xl font-monospace">
              <span v-if="waitingListSuccess" class="mb-12">
                {{ $t('search.alertSubscribed', { desti: destination.name }) }}
              </span>
              <span v-else-if="alreadySubscribed">
                {{ $t('search.alreadySubscribed') }}
              </span>
              <span v-else>{{ $t('search.beFirst') }}</span>
            </div>
            <p class="mt-2 mb-12 text-center md:text-left">
              <span>
                <template v-if="waitingListSuccess || alreadySubscribed">
                  {{ $t('search.discover') }}
                </template>
                <template v-else>
                  {{ $t('search.interested') }}<br />
                  {{ $t('search.beInformed') }}
                </template>
              </span>
            </p>

            <BaseButton
              v-if="waitingListSuccess || alreadySubscribed"
              color="secondary"
              block-full
              @click="submitNewsletter"
            >
              <span>{{ $t('search.newsletter') }}</span>
              <BaseIcon class="ml-4" name="check" />
            </BaseButton>

            <BaseForm
              v-else
              ref="waitingListForm"
              :class="{
                'waiting-list-form-desktop flex items-start': !isMobile,
              }"
              :active-modal-errors="false"
              :active-modal-save-or-quit="false"
              :button-primary="btnPrimary"
              :container-button-class="
                isMobile ? 'flex w-full' : 'flex w-1/3 justify-end'
              "
              :group-fields="
                isMobile
                  ? groupFieldsFormWaitingListMobile
                  : groupFieldsFormWaitingListDesktop
              "
              :initial-data="initialData"
              :mutation="mutationWaitingList"
              :on-submit="onSubmitWaitingList"
              id-form="waiting-list-form"
              container-form-class="w-full"
              wrapper-button-class="w-full"
            />
          </div>
        </div>
      </template>
    </BaseMediaObject>
  </div>
</template>

<style>
.waiting-list-form-desktop .lc-btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
</style>
