import type { DateString } from '~/helpers/utils/regex'
import type { StateSearchDateFilters } from '~/types/search/types'

export const useSearchDateFilters = () => {
  const stateSearchDateFilters = useState<StateSearchDateFilters>(
    'state-search-date-filter',
  )

  const { $httpCookies } = useNuxtApp()
  const route = useRoute()
  const router = useRouter()

  const startDate = computed(
    () => stateSearchDateFilters.value.startDate as DateString,
  )
  const endDate = computed(
    () => stateSearchDateFilters.value.endDate as DateString,
  )
  const hasDates = computed(() => Boolean(startDate.value && endDate.value))

  const hasDateCookie = () => {
    const cookies = $httpCookies?.get('search_params')

    return !!(cookies?.from_date && cookies?.to_date)
  }

  const getAbsoluteUrl = (to: any) => {
    const path = router.resolve(to).href

    return window.location.origin + path
  }

  const clearDateFilters = () => {
    $httpCookies?.set('search_params', {})

    stateSearchDateFilters.value.endDate = null
    stateSearchDateFilters.value.startDate = null
  }

  const setDateStart = (date: Date) => {
    stateSearchDateFilters.value.startDate = date
      ? ($dayjs(date).format('YYYY-MM-DD') as DateString)
      : null
  }

  const setDateEnd = (date: Date) => {
    stateSearchDateFilters.value.endDate = date
      ? ($dayjs(date).format('YYYY-MM-DD') as DateString)
      : null

    setCookieDates()
  }

  const setCookieDates = () => {
    if (startDate.value && endDate.value) {
      const start = $dayjs(startDate.value)
      const end = $dayjs(endDate.value)

      $httpCookies?.set('search_params', {
        from_date: start.format('YYYY-MM-DD'),
        to_date: end.format('YYYY-MM-DD'),
      })
    } else if (startDate.value && !endDate.value) {
      $httpCookies?.set('search_params', {})
    }
  }

  onBeforeMount(() => {
    if (hasDateCookie() && startDate.value && endDate.value) {
      const url = getAbsoluteUrl({
        params: {
          ...route.params,
        },
        query: {
          ...route?.query,
          startDate: startDate.value,
          endDate: endDate.value,
        },
      })
      history.replaceState({}, '', url)
    }
  })

  return {
    clearDateFilters,
    setDateStart,
    setDateEnd,
    startDate,
    endDate,
    hasDates,
  }
}
