<script setup lang="ts">
const localePath = useLocalePath()

const buttonClicked = ref(false)
</script>

<template>
  <div class="relative h-full bg-secondary-100 py-14 px-7 text-sm">
    {{ $t('search.criteria') }}
    <br />
    <div class="font-bold mt-5">
      {{ $t('search.moreDetails') }}
    </div>
    <h6
      class="mt-8 pt-8 xxl:mt-6 xxl:pt-6 border-t border-gray-200 font-sansSerif text-base text-secondary-500"
    >
      {{ $t('search.needHelp') }}
    </h6>
    <div class="mt-5">
      <base-sliding-input-container
        :button-text="
          buttonClicked ? $t('global.validate') : $t('global.ctaCallMe')
        "
        :no-icon="buttonClicked"
        :notes="localePath('search')"
        class="mb-4 text-md"
        nocenter
        section="results"
        @click="buttonClicked = !buttonClicked"
      />
      <base-button
        :to="localePath('contact')"
        class="text-md"
        color="black"
        has-icon
        variant="link"
      >
        <BaseIcon class="mr-3 md:mr-4" name="mail" />
        <span class="pl-2">{{ $t('global.contactUs') }}</span>
      </base-button>
    </div>
  </div>
</template>
