import type { LocationQuery } from 'vue-router'

export const createQueryMultipleDestinationIds = (query: LocationQuery) => {
  if (
    query?.multipleDestinationsIds &&
    typeof query.multipleDestinationsIds === 'string'
  ) {
    return query.multipleDestinationsIds.split(',')
  }

  return []
}
