import type { Locale } from 'lc-services/types'
import { buildFiltersAppliedSearchTracking } from '~/helpers/search/searchTracking'

export const useSearchTracking = () => {
  const { trackEvent } = useTracking()
  const { locale } = useI18n()

  const { bathrooms, bedrooms, capacity } = useSearchNumericFilters()
  const { facetsFiltersParams } = useSearchFacetFilters()
  const { startDate, endDate } = useSearchDateFilters()
  const { minBudget, maxBudget } = useSearchBudgetFilters()
  const { nbHits } = useSearchResults({ startDate, endDate })

  const filtersAppliedPayload = (
    searchableTagsIdentifiers: Record<string, { en: string; fr: string }>[],
  ) => {
    return buildFiltersAppliedSearchTracking({
      capacity: capacity.value,
      bedrooms: bedrooms.value,
      bathrooms: bathrooms.value,
      destinationId: '1',
      endDate: endDate.value,
      facetsFiltersParams: facetsFiltersParams.value,
      maxBudget: maxBudget.value,
      minBudget: minBudget.value,
      startDate: startDate.value,
      locale: locale.value as Locale,
      searchableTagsIdentifiers: searchableTagsIdentifiers || [],
    })
  }

  const trackSearchHouse = ({
    houseId,
    mapVisible,
    searchableTagsIdentifiers = [],
  }: {
    houseId: number
    mapVisible: boolean
    searchableTagsIdentifiers: Record<string, { en: string; fr: string }>[]
  }) => {
    const filtersApplied = filtersAppliedPayload(searchableTagsIdentifiers)
    const tracking = {
      category: 'Search',
      event: 'click_on_card',
      house_id: houseId,
      is_map_view: mapVisible,
      filters_applied: filtersApplied,
    }

    trackEvent(tracking)
  }

  const trackSearchResultsViewed = ({
    mapVisible,
    searchableTagsIdentifiers = [],
  }: {
    mapVisible: boolean
    searchableTagsIdentifiers: Record<string, { en: string; fr: string }>[]
  }) => {
    const filtersApplied = filtersAppliedPayload(searchableTagsIdentifiers)
    const tracking = {
      category: 'Search',
      event: 'search_results_viewed',
      search_results: nbHits.value,
      result_view: mapVisible ? 'map' : 'list',
      filters_applied: filtersApplied,
    }

    trackEvent(tracking)
  }

  const trackSearchMap = ({ event }: { event: 'map_close' | 'map_open' }) => {
    const { query } = useRoute()
    const searchQuery = decodeURIComponent(
      new URLSearchParams(Object(query)).toString(),
    )

    trackEvent({
      category: 'Search',
      event,
      search_query: searchQuery,
    })
  }

  return {
    trackSearchHouse,
    trackSearchMap,
    trackSearchResultsViewed,
  }
}
