import { setPriceFilter } from '~/helpers/search/getBudget'
import type { StateSearchBudgetFilters } from '~/types/search/types'

export const useSearchBudgetFilters = () => {
  const stateSearchBudgetFilters = useState<StateSearchBudgetFilters>(
    'state-search-budget-filter',
  )

  const minBudget = computed(() => stateSearchBudgetFilters.value.minBudget)
  const maxBudget = computed(() => stateSearchBudgetFilters.value.maxBudget)

  const price = computed(() => {
    return setPriceFilter({
      minBudget: stateSearchBudgetFilters.value.minBudget,
      maxBudget: stateSearchBudgetFilters.value.maxBudget,
    })
  })

  const clearBudgetFilters = () => {
    stateSearchBudgetFilters.value.minBudget = '0'
    stateSearchBudgetFilters.value.maxBudget = '1000000'
  }

  const setBudgetFilters = (minBudget: string, maxBudget: string) => {
    stateSearchBudgetFilters.value.minBudget = minBudget
    stateSearchBudgetFilters.value.maxBudget = maxBudget
  }

  return {
    setBudgetFilters,
    clearBudgetFilters,
    price,
    minBudget,
    maxBudget,
  }
}
