import type { LocationQuery } from 'vue-router'
import type { ReplicasValues } from '~/types/search/types'
import { $dayjs } from '~/utils/dayjs'

type CreateRouteFilters = {
  currency: ComputedRef<string>
  currentReplica: ComputedRef<{ value: string; label: ReplicasValues }>
  destinationId: number | null
  endDate: ComputedRef<string | null>
  facetsFiltersParams: ComputedRef<string[]>
  maxBudgetComputed: ComputedRef<string>
  minBudgetComputed: ComputedRef<string>
  multipleDestinationsIds: Ref<string[]>
  multipleHousesIds: Ref<string[]>
  numericFilters: ComputedRef<string[]>
  page: ComputedRef<number>
  parentDestiId: Ref<number | null>
  disableQuery: boolean
  startDate: ComputedRef<string | null>
  userIsAdmin: Ref<boolean>
  userIsPartner: Ref<boolean>
}

export const createRouteFilters = ({
  currency,
  currentReplica,
  destinationId,
  endDate,
  facetsFiltersParams,
  maxBudgetComputed,
  minBudgetComputed,
  multipleDestinationsIds,
  multipleHousesIds,
  numericFilters,
  page,
  parentDestiId,
  disableQuery,
  startDate,
  userIsAdmin,
  userIsPartner,
}: CreateRouteFilters) => {
  const formatDate = (date: string, format: 'YYYY-MM-DD') => {
    const d = $dayjs(date)

    return d.format(format)
  }

  // Create route filters
  let routeFilter: LocationQuery = {}

  // Set budget filter (min/max price)
  if (
    maxBudgetComputed.value !== '1000000' ||
    minBudgetComputed.value !== '0'
  ) {
    routeFilter = {
      minPrice: minBudgetComputed.value,
      maxPrice: maxBudgetComputed.value,
      currency: currency.value,
    }
  }

  // Date + House id
  if (userIsAdmin.value || userIsPartner.value) {
    if (startDate.value && endDate.value) {
      routeFilter = {
        ...routeFilter,
        startDate: formatDate(startDate.value, 'YYYY-MM-DD'),
        endDate: formatDate(endDate.value, 'YYYY-MM-DD'),
      }

      if (multipleHousesIds.value.length > 0) {
        routeFilter = {
          ...routeFilter,
          multipleHousesIds: multipleHousesIds.value.join(','),
        }
      }
    } else if (multipleHousesIds.value.length > 0) {
      // MultipleHousesIds contains ids without date
      routeFilter = {
        ...routeFilter,
        multipleHousesIds: multipleHousesIds.value.join(','),
      }
    }
  } else if (startDate.value && endDate.value) {
    routeFilter = {
      ...routeFilter,
      startDate: formatDate(startDate.value, 'YYYY-MM-DD'),
      endDate: formatDate(endDate.value, 'YYYY-MM-DD'),
    }
  }

  // FacetFilters
  if (facetsFiltersParams.value.length) {
    routeFilter = {
      ...routeFilter,
      facetFilter: facetsFiltersParams.value,
    }
  }

  // NumericFilter
  if (Array.isArray(numericFilters.value) && numericFilters.value.length > 0) {
    const newNumericFilters = numericFilters.value.join(' AND ')

    routeFilter = {
      ...routeFilter,
      numericFilters: newNumericFilters,
    }
  }

  // DestinationIds
  if (
    (userIsAdmin.value || userIsPartner.value) &&
    multipleDestinationsIds.value?.length > 0
  ) {
    routeFilter = {
      ...routeFilter,
      multipleDestinationsIds: multipleDestinationsIds.value.join(','),
    }
  } else if (!disableQuery && destinationId && parentDestiId.value === null) {
    routeFilter = {
      ...routeFilter,
      destinationId,
    }
  }

  // IndexName
  if (currentReplica.value.label !== 'recommended') {
    routeFilter = {
      ...routeFilter,
      indexName: currentReplica.value.label,
    }
  }

  // Page
  if (page.value > 0) {
    routeFilter = {
      ...routeFilter,
      page: page.value,
    }
  }
  return routeFilter
}
