<script setup lang="ts">
const emits = defineEmits<{
  'toggle-filters': []
}>()

withDefaults(
  defineProps<{
    disabled: boolean
  }>(),
  {
    disabled: false,
  },
)
const { filtersCount } = useSearchFiltersCount()

const handleFilterClick = () => {
  emits('toggle-filters')
}
</script>

<template>
  <BaseButton
    :disabled="disabled"
    class="h-[51px] !text-md !font-medium !border-gray-700 !px-4 relative"
    color="black"
    data-cy="filters"
    has-icon
    @click="handleFilterClick"
  >
    <BaseIcon class="mr-2" name="filterLc" />
    {{ $t('search.filters') }}
    ({{ filtersCount }})
  </BaseButton>
</template>
