import { algoliaSearch } from '../index'

export const getDestinationHousesCount = ({
  config,
  query,
  filters,
}: {
  config: any
  query: string
  filters?: string
}) => {
  const defaultFilter = 'NOT houses_count=0 AND NOT state:hidden'

  return algoliaSearch({ indexName: 'public_destinations', config }).search(
    query,
    {
      attributes: ['houses_count', 'id'],
      hitsPerPage: 20,
      responseFields: ['hits'],
      filters: filters?.length ? filters : defaultFilter,
    },
  )
}
