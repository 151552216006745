export const useSearchMap = (
  searchContainer: Ref<HTMLElement | null>,
  mapVisible: Ref<boolean>,
) => {
  const showMapButton = ref(true)

  const handleShowMapButton = () => {
    let condition

    if (searchContainer.value) {
      const searchContainerHeight =
        searchContainer.value.getBoundingClientRect().height
      if (mapVisible.value) {
        // Add offset if map is visible
        condition =
          window.scrollY + window.innerHeight > searchContainerHeight + 200
      } else {
        condition = window.scrollY + window.innerHeight > searchContainerHeight
      }

      if (condition) {
        showMapButton.value = false
      } else {
        showMapButton.value = true
      }
    }
  }

  onBeforeMount(() => {
    document.addEventListener('scroll', handleShowMapButton)
  })

  onUnmounted(() => {
    document.removeEventListener('scroll', handleShowMapButton)
  })

  return {
    showMapButton,
  }
}
