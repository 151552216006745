import { getDestinationHousesCount } from '~/utils/algolia/services'
import { getDestinationSlug } from '~/helpers/getDestinationSlug'
import { getFacetsFilterByDestination } from '~/helpers/strapi/getFacetsFilter'

const getDestination = async ({ $api, config, route, params }) => {
  try {
    const [destinationData, responseHitsData] = await Promise.all([
      $api.v3.destination.getPhotoAndAncestries({
        ...params,
      }),
      getDestinationHousesCount({ query: route.params.name, config }),
    ])

    // ancestriesData
    if (destinationData) {
      const housesCount = responseHitsData?.hits?.length
        ? responseHitsData.hits[0].houses_count
        : 0
      const metaDescription = destinationData.metaDescription
      const metaTitle = destinationData.metaTitle

      return {
        metaDescription,
        metaTitle,
        destination: {
          destinationSlug: getDestinationSlug(destinationData.searchUrl),
          ...destinationData,
          housesCount,
        },
      }
    }
  } catch {
    return {}
  }
}

export const useSearchDesti = async ({ $api, $strapiApi, config, route }) => {
  const { userIsAdmin, userIsPartner } = useAuth()
  const collections = ref([])
  const destination = ref({})
  const lpContent = ref({})
  let metaDesc = ''
  let metaTitle = ''
  let metaCoverPhotoUrl = ''
  const linksSeo = []

  if (route.params.name) {
    const res = await getDestination({
      $api,
      config,
      route,
      params: {
        slug: route.params.name,
      },
    })

    if (res?.destination?.id && !userIsAdmin.value && !userIsPartner.value) {
      const filterFacets = await getFacetsFilterByDestination({
        $strapiApi,
        destinationId: res.destination.id,
      })

      if (filterFacets?.length) linksSeo.push(...filterFacets)
    }

    destination.value = res?.destination || {}

    metaTitle = res?.destination?.metaTitle || ''
    metaDesc = res?.destination?.metaDescription || ''
    metaCoverPhotoUrl = res?.destination?.seoPhotoUrl || ''
  } else if (route.query.destinationId) {
    const getDestinationQueryData = await getDestination({
      $api,
      config,
      route,
      params: {
        id: route.query.destinationId,
      },
    })
    destination.value = getDestinationQueryData?.destination || {}
  }

  return {
    collections,
    destination,
    destinations: ref([]),
    linksSeo,
    lpContent,
    metaCoverPhotoUrl,
    metaDesc,
    metaTitle,
  }
}
