import type { ReplicasValues } from '~/types/search/types'

export const constructReplica = ({ config, userType }) => {
  const scope = userType
  const indexPrefix = `${config.public.algoliaIndexPrefix}_${scope}_houses`
  const adminCommissionReplica = ref<
    {
      label: ReplicasValues
      value: string
    }[]
  >([
    {
      value: `${indexPrefix}_commission_rate_asc`,
      label: 'commission_asc',
    },
    {
      value: `${indexPrefix}_commission_rate_desc`,
      label: 'commission_desc',
    },
  ])

  const defaultReplicas = ref<
    {
      label: ReplicasValues
      value: string
    }[]
  >([
    {
      value: `${indexPrefix}_is_pinned_house_desc`,
      label: 'recommended',
    },
    {
      value: `${indexPrefix}_price_asc`,
      label: 'price_asc',
    },
    {
      value: `${indexPrefix}_price_desc`,
      label: 'price_desc',
    },
    {
      value: `${indexPrefix}_bedrooms_asc`,
      label: 'bedroom_asc',
    },
    {
      value: `${indexPrefix}_bedrooms_desc`,
      label: 'bedroom_desc',
    },
  ])

  if (userType === 'admin') {
    return ref([...defaultReplicas.value, ...adminCommissionReplica.value])
  } else {
    return defaultReplicas
  }
}
