import {
  getFacetsFilterWithDestination,
  getFacetsFilterWithoutDestination,
} from '~/helpers/strapi/getFacetsFilter'
import { getDestinationSlug } from '~/helpers/getDestinationSlug'

export const getDestination = async ({ $api, params }) => {
  try {
    const destination = await $api.v3.destination.getPhoto({ ...params })

    return {
      destination: {
        slug: getDestinationSlug(destination.searchUrl),
        ...destination,
      },
    }
  } catch (err) {
    console.error(err)
  }
}

const withoutDestination = async ({ $strapiApi, locale, route }) => {
  const responseFilterData = await getFacetsFilterWithoutDestination({
    slug: route.params.name,
    $strapiApi,
  })

  return {
    indexation: responseFilterData?.indexation,
    metaTitle: responseFilterData?.metaTitle[locale.value],
    metaDesc: responseFilterData?.metaDescription[locale.value],
    seoTitle: responseFilterData?.title[locale.value],
  }
}

const withDestination = async ({ $api, $strapiApi, locale, route }) => {
  const destinationResponse = await getDestination({
    $api,
    params: {
      slug: route.params.slug,
    },
    route,
  })

  const responseFilterData = await getFacetsFilterWithDestination({
    destination_slug: route.params.slug,
    slug: route.params.filter,
    $strapiApi,
  })

  return {
    destination: destinationResponse?.destination || {},
    indexation: responseFilterData?.indexation,
    metaTitle: responseFilterData?.metaTitle[locale.value],
    metaDesc: responseFilterData?.metaDescription[locale.value],
    seoTitle: responseFilterData?.title[locale.value],
  }
}

export const useSearchFilterUrl = async ({
  $api,
  $strapiApi,
  locale,
  route,
}) => {
  const destinationDefault = ref({})

  if (route.params?.name) {
    const { metaTitle, metaDesc, seoTitle, indexation } =
      await withoutDestination({
        $strapiApi,
        locale,
        route,
      })

    return {
      destination: destinationDefault,
      indexation,
      metaDesc,
      metaTitle,
      seoTitle,
    }
  } else {
    const { destination, metaTitle, metaDesc, seoTitle, indexation } =
      await withDestination({ $api, $strapiApi, locale, route })

    destinationDefault.value = destination

    return {
      destination: destinationDefault,
      indexation,
      metaDesc,
      metaTitle,
      seoTitle,
    }
  }
}
