import type { Locale } from 'lc-services/types'
import type { SearchFacetValue } from '~/types/search/types'

export const topFacetsAdmin: SearchFacetValue<Record<Locale, string>, false>[] =
  [
    {
      value: { fr: 'Piscine intérieure', en: 'Interior pool' },
      checked: false,
      key: 'other_tags',
    },
    {
      value: { fr: 'Piscine chauffée', en: 'Heated pool' },
      checked: false,
      key: 'other_tags',
    },
    {
      value: { fr: 'Salle de sport', en: 'Fitness room' },
      checked: false,
      key: 'other_tags',
    },
    {
      value: { fr: 'Animaux acceptés', en: 'Pet friendly' },
      checked: false,
      key: 3,
    },
    {
      value: { fr: 'Ski-in/Ski-out', en: 'Ski-in/Ski-out' },
      checked: false,
      key: 2,
    },
    {
      value: {
        fr: 'Centre ville accessible à pied',
        en: 'City center within walking distance',
      },
      checked: false,
      key: 2,
    },
    {
      value: {
        fr: 'Plage accessible à pied',
        en: 'Beach within walking distance',
      },
      checked: false,
      key: 2,
    },
    {
      value: {
        fr: 'Vue mer',
        en: 'Sea view',
      },
      checked: false,
      key: 2,
    },
    {
      value: {
        fr: "Villa pieds dans l'eau",
        en: 'Beachfront',
      },
      checked: false,
      key: 2,
    },
    {
      value: { fr: 'Adapté aux évènements', en: 'Suitable for events' },
      checked: false,
      key: 3,
    },
    {
      value: { fr: 'Terrain de padel', en: 'Padel court' },
      checked: false,
      key: 'other_tags',
    },
  ]

export const topFacetsClient: SearchFacetValue<
  Record<Locale, string>,
  false
>[] = [
  {
    value: { fr: 'Villa', en: 'Villa' },
    checked: false,
    key: 1,
  },
  {
    value: { fr: 'Piscine', en: 'Swimming pool' },
    checked: false,
    key: 7,
  },
  {
    value: { fr: 'Climatisation', en: 'Air conditioning' },
    checked: false,
    key: 4,
  },
  {
    value: {
      fr: 'Plage accessible à pied',
      en: 'Beach within walking distance',
    },
    checked: false,
    key: 2,
  },
  {
    value: {
      fr: 'Vue mer',
      en: 'Sea view',
    },
    checked: false,
    key: 2,
  },
  {
    value: {
      fr: 'Adapté aux enfants',
      en: 'Suitable for children',
    },
    checked: false,
    key: 3,
  },
  {
    value: { fr: 'Terrain de padel', en: 'Padel court' },
    checked: false,
    key: 7,
  },
]
