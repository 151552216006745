<script setup lang="ts">
import type { Locale } from 'lc-services/types'

defineEmits<{
  click: [`searchable_tags.5.${Locale}`, string]
}>()
const { locale, t } = useI18n<unknown, Locale>()
const { trackEvent } = useTracking()
const route = useRoute()

const tagName = computed(() => t('global.hotdeal'))
const tag = `searchable_tags.5.${locale.value}` as const
const searchQuery = computed(() => `${tag}: ${tagName.value}`)
const isSearchingHotDeals = computed(() =>
  route.query.facetFilter?.includes(searchQuery.value),
)
const hotDealsPath = computed(() => {
  switch (locale.value) {
    case 'en':
      return { to: '/en/luxury-villas-rentals/book-now-for-summer' }
    case 'fr':
      return { to: '/fr/location-villas-luxe/reserver-pour-l-ete' }
    default:
      return {}
  }
})
const onClickHotDeals = () => {
  trackEvent({
    event: 'search_favourites_click',
    search_query: searchQuery,
  })
}
</script>

<template>
  <BaseRow
    v-if="!isSearchingHotDeals"
    class="bg-primary-100 ml-0.5 mr-0.5 mb-4 p-4 justify-center items-center flex-col text-center"
  >
    <i18n-t
      keypath="search.hotdeals.text"
      tag="p"
      class="m-0 whitespace-pre-line"
    >
      <template #bold>
        <strong>{{ $t('search.hotdeals.textBold') }}</strong>
      </template>
    </i18n-t>
    <BaseButton
      color="black"
      variant="link"
      class="underline"
      v-bind="hotDealsPath"
      @click.prevent="onClickHotDeals"
    >
      {{ $t('search.hotdeals.link') }}
    </BaseButton>
  </BaseRow>
</template>
