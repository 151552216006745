<script setup lang="ts">
import type { SearchFacetObject, SearchFacetValue } from '~/types/search/types'

const props = withDefaults(
  defineProps<{
    collapse?: boolean
    collapseOpenDefault?: boolean
    displayRow?: boolean
    facet?: SearchFacetObject<SearchFacetValue[]>
    loadingFacets?: boolean
    sortAlphabeticResults?: boolean
    title?: string
    type?: 'labels' | 'checkboxes'
  }>(),
  {
    collapse: false,
    collapseOpenDefault: false,
    displayRow: false,
    facet: () =>
      ({ values: [] }) as unknown as SearchFacetObject<SearchFacetValue[]>,
    loadingFacets: false,
    title: '',
    type: 'labels',
    sortAlphabeticResults: true,
  },
)

const emits = defineEmits<{
  'change-facet': [typeof props.facet, SearchFacetValue]
}>()

defineSlots<{
  'filter-button'(): any
  'filter-button-checkbox'(): any
}>()

const options = computed(() => {
  const options =
    props.facet?.values.map((facetValue) => ({
      ...(facetValue.description && {
        description: facetValue.description,
      }),
      isActive: facetValue.checked,
      label: facetValue.value,
      value: [props.facet, facetValue] as [
        typeof props.facet,
        SearchFacetValue,
      ],
    })) || []

  if (props.sortAlphabeticResults) {
    return options.sort((a, b) => a.label.localeCompare(b.label))
  }

  return options
})

const selectFacet = (
  facet: typeof props.facet,
  facetValue: SearchFacetValue,
) => {
  emits('change-facet', facet, facetValue)
}
</script>

<template>
  <SearchFilter
    v-if="options.length"
    :title="title"
    :collapse="collapse"
    :collapse-open-default="collapseOpenDefault"
  >
    <SearchFilterLabels
      v-if="type === 'labels'"
      :disabled="loadingFacets"
      :options="options"
      @select="selectFacet"
    >
      <template #filter-button>
        <slot name="filter-button" />
      </template>
    </SearchFilterLabels>

    <slot name="filter-button-checkbox" />
    <SearchFilterCheckboxes
      v-if="type === 'checkboxes'"
      :disabled="loadingFacets"
      :display-row="displayRow"
      :options="options"
      @select="selectFacet"
    />
  </SearchFilter>
</template>
