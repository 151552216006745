import { formatFacets } from '~/helpers/search/createFacetFilters'

import type {
  SearchFacetObject,
  SearchFacetValue,
  StateSearchFacetFilter,
} from '~/types/search/types'

export const useSearchFacetFilters = () => {
  const { locale } = useI18n()
  const stateSearchFacetFilter = useState<StateSearchFacetFilter>(
    'state-search-facet-filter',
  )

  const facetFilters = computed(() => stateSearchFacetFilter.value.facetFilters)

  const facetFiltersKey = computed(() => {
    const entries = Object.entries(stateSearchFacetFilter.value.facetFilters)

    return entries?.reduce<(number | 'other_tags')[]>((acc, [indexTag]) => {
      const value = indexTag !== 'other_tags' ? Number(indexTag) : indexTag
      if (!acc.includes(value)) acc.push(value)

      return acc
    }, [])
  })

  const facetsFiltersParams = computed(() => {
    const entries = Object.entries(stateSearchFacetFilter.value.facetFilters)

    return entries?.reduce<string[]>((acc, [_, facets]) => {
      if (facets.length) acc.push(...facets)

      return acc
    }, [])
  })

  const newFacetFilters = computed(() =>
    formatFacets({
      facetFiltersKey,
      facetFilters: stateSearchFacetFilter.value.facetFilters,
      locale,
    }),
  )

  const clearAllFacetFilters = () => {
    stateSearchFacetFilter.value.facetFilters = {}
  }

  const setFacetFilter = (
    facet: SearchFacetObject<SearchFacetValue<string>[]>,
    facetValue: SearchFacetValue,
  ) => {
    const facetQuery = `${facet.tag}: ${facetValue.value}`
    const { facetFilters } = stateSearchFacetFilter.value

    if (facetFilters[facet.key]) {
      // unCheck Facet
      if (facetFilters[facet.key].includes(facetQuery)) {
        const index = facetFilters[facet.key].findIndex((x) => x === facetQuery)
        if (facetFilters[facet.key].length > 1) {
          if (index === 0) {
            facetFilters[facet.key].shift()
          } else if (index + 1 === facetFilters[facet.key].length) {
            facetFilters[facet.key].pop()
          } else {
            facetFilters[facet.key].splice(1, index)
          }
        } else {
          facetFilters[facet.key] = facetFilters[facet.key].splice(1, index)
        }

        // Remove empty key of facetFilter
        // {1: [], 2: ['...']} => {2: ['...']}
        if (facetFilters[facet.key].length === 0) {
          delete facetFilters[facet.key]
        }
      } else {
        facetFilters[facet.key].push(facetQuery)
      }
    } else {
      facetFilters[facet.key] = []
      facetFilters[facet.key].push(facetQuery)
    }
  }

  return {
    newFacetFilters,
    facetsFiltersParams,
    facetFilters,
    clearAllFacetFilters,
    setFacetFilter,
  }
}
