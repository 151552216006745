export const useSearchWishlist = () => {
  const currentHouseFavoriteSelected = ref<{
    houseId: number | null
    houseName: string
    iconic?: boolean
    onlineBookingAvailable?: boolean
    price?: number
  }>({
    houseId: null,
    houseName: '',
    iconic: undefined,
    onlineBookingAvailable: undefined,
    price: undefined,
  })

  const setCurrentHouseFavorite = (house: {
    id: number
    name: string
    iconicCollection: boolean
    publicPrice?: number
  }) => {
    currentHouseFavoriteSelected.value = {
      houseId: house.id,
      houseName: house.name,
      iconic: house.iconicCollection,
      onlineBookingAvailable: undefined,
      price: house?.publicPrice,
    }
  }

  return {
    currentHouseFavoriteSelected,
    setCurrentHouseFavorite,
  }
}
