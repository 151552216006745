import type { LocationQuery } from 'vue-router'
import type { ReplicasValues } from '~/types/search/types'

export const findReplica = (
  replicas: Ref<
    {
      label: ReplicasValues
      value: string
    }[]
  >,
  query?: LocationQuery,
): Ref<{ label: ReplicasValues; value: string }> => {
  const replicaLabel =
    replicas.value.find((r) => r.label === query?.indexName)?.label ||
    'recommended'

  if (replicaLabel) {
    return ref({
      label: replicaLabel,
      value: replicas.value.find((r) => r.label === replicaLabel)?.value || '',
    })
  }

  console.error('No replica found, using first replica')

  return ref(replicas.value[0])
}
