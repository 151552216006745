import type {
  StateSearchNumericFilter,
  NumericFilter,
} from '~/types/search/types'

export const useSearchNumericFilters = () => {
  const stateSearchNumericFilter = useState<StateSearchNumericFilter>(
    'state-search-numeric-filter',
  )

  const bathrooms = computed(
    () => stateSearchNumericFilter.value.selectedNumericFilters.bathrooms || 0,
  )
  const bedrooms = computed(
    () => stateSearchNumericFilter.value.selectedNumericFilters.bedrooms || 0,
  )
  const capacity = computed(
    () => stateSearchNumericFilter.value.selectedNumericFilters.capacity || 0,
  )

  const numericFilters = computed(() => {
    const array = []
    if (bathrooms.value) array.push(`bathrooms >= ${bathrooms.value}`)
    if (bedrooms.value) array.push(`bedrooms >= ${bedrooms.value}`)
    if (capacity.value) array.push(`capacity >= ${capacity.value}`)

    return array
  })

  const setNumericFilter = (
    attribute: NumericFilter,
    selectedFilter: number | string,
  ) => {
    stateSearchNumericFilter.value.selectedNumericFilters[attribute] =
      selectedFilter ? Number(selectedFilter) : 0
  }

  const clearNumericFilterByType = (type: NumericFilter) => {
    stateSearchNumericFilter.value.selectedNumericFilters[type] = 0
  }

  const clearAllNumericFilters = () => {
    const { selectedNumericFilters } = stateSearchNumericFilter.value

    selectedNumericFilters.bathrooms = 0
    selectedNumericFilters.bedrooms = 0
    selectedNumericFilters.capacity = 0
  }

  return {
    numericFilters,
    setNumericFilter,
    clearNumericFilterByType,
    clearAllNumericFilters,
    bathrooms,
    bedrooms,
    capacity,
  }
}
