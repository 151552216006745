import { createRouteFilters } from '~/helpers/search/createRouteFilters'

export const useSearchUpdateRouter = () => {
  const router = useRouter()
  const { currency } = useUser()

  const { userIsAdmin, userIsPartner } = useAuth()

  const { currentReplica } = useSearchReplicaFilters()
  const { startDate, endDate } = useSearchDateFilters()
  const { minBudget, maxBudget } = useSearchBudgetFilters()
  const { facetsFiltersParams } = useSearchFacetFilters()
  const { numericFilters } = useSearchNumericFilters()
  const { queryDestinationIds, queryHouseIds, queryValue } =
    useSearchLocationFilters()
  const { page } = useSearchResults({ startDate, endDate })

  const updateRouter = (isIndexesFilter: boolean, disableQuery: boolean) => {
    if (import.meta.client && !isIndexesFilter) {
      const routeFilter = createRouteFilters({
        currency,
        currentReplica,
        destinationId: queryValue.value.value,
        disableQuery,
        endDate,
        facetsFiltersParams,
        maxBudgetComputed: maxBudget,
        minBudgetComputed: minBudget,
        multipleDestinationsIds: queryDestinationIds,
        multipleHousesIds: queryHouseIds,
        numericFilters,
        page,
        parentDestiId: ref(null),
        startDate,
        userIsAdmin,
        userIsPartner,
      })

      if (Object.keys(routeFilter).length > 0) {
        router.push({ query: { ...routeFilter } })
      } else {
        router.push({ query: {} })
      }
    }
  }

  return {
    updateRouter,
  }
}
